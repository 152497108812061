import { useCallback, useEffect, useState, memo } from "react";
import SingleCharacterInput from "./SingleCharacterInput";
import { NOT_ALLOW_SEPCIAL_CHARACTER } from "../../../constant";

const InputOTP = (props) => {
  const {
    length,
    isNumberInput,
    autoFocus,
    disabled,
    value,
    onChange,
    inputClassName,
    ...rest
  } = props;

  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(Array(length).fill(""));

  useEffect(() => {
    if (value === "") {
      setOTPValues(Array(length).fill(""));
      setActiveInput(0);
    }
  }, [value, length]);

  // Helper to return OTP from inputs
  const handleCharacterChange = useCallback(
    (otp) => {
      const otpValue = otp.join("");
      onChange(otpValue);
    },
    [onChange]
  );

  // Helper to return value with the right type: 'text' or 'number'
  const getRightValue = useCallback(
    (value) => {
      const changedValue = value;
      if (!isNumberInput || !changedValue) {
        return changedValue;
      }
      return Number(changedValue) >= 0 ? changedValue : "";
    },
    [isNumberInput]
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (stringValue) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = stringValue[0] || "";
      setOTPValues(updatedOTPValues);
      handleCharacterChange(updatedOTPValues);
    },
    [activeInput, handleCharacterChange, otpValues]
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e) => {
      const value = getRightValue(e.currentTarget.value);
      if (!value) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(value);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  );

  // Handle onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e) => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case "Backspace":
        case "Delete": {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus("");
          } else {
            focusPrevInput();
          }
          break;
        }
        case "ArrowLeft": {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case "ArrowRight": {
          e.preventDefault();
          focusNextInput();
          break;
        }
        default: {
          if (pressedKey.match(NOT_ALLOW_SEPCIAL_CHARACTER)) {
            e.preventDefault();
          }
          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  const handleOnPaste = useCallback(
    (e) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData("text/plain")
        .trim()
        .slice(0, length - activeInput)
        .split("");

      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((value, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || value);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });

        setOTPValues(updatedOTPValues);
        handleCharacterChange(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    [activeInput, getRightValue, length, otpValues, handleCharacterChange]
  );

  return (
    <div
      className="bg-gray-500 w-full border-none flex justify-start rounded-lg"
      {...rest}
    >
      {Array(length)
        .fill("")
        .map((_, index) => (
          <SingleCharacterInput
            key={`SingleInput-${index}`}
            type={rest.type}
            focus={activeInput === index}
            value={otpValues && otpValues[index]}
            autoFocus={autoFocus}
            onFocus={handleOnFocus(index)}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onBlur={onBlur}
            onPaste={handleOnPaste}
            disabled={disabled}
            className={`${inputClassName}`}
          />
        ))}
    </div>
  );
};

export default memo(InputOTP);
