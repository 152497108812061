import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toggleWishlist } from "../../../apis/game/wishlist";
import { queryContants } from "../../../constant";

export const useWishlistMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: toggleWishlist,
    onSuccess: () => {
      queryClient.invalidateQueries(queryContants.GET_GAME);
      queryClient.invalidateQueries(queryContants.GET_WISHLIST);
    },
  });
};
