import { memo, useContext, useEffect, useState } from "react";
import Header from "../Header/Header";
import { Outlet } from "react-router-dom";
import { useGetMeQuery, useGetVipQuery } from "../../store/queries/auth.query";
import { StateContext } from "../../context/reducer";
import { getMe } from "../../context/actions";
import LoadingVideo from "../Common/Loading/LoadingVideo";
// import { useUpdateVipMutation } from "../../store/mutations/auth.mutate";
import { socket } from "../../helpers/socket.helper";
import { electronConstants } from "../../constant";

const Layout = () => {
  const { state, dispatch } = useContext(StateContext);
  const { user } = state;
  const isVip = !!user?.is_vip && user?.is_vip !== "0";

  const { isLoading, data: userData } = useGetMeQuery();
  const { data: vipData } = useGetVipQuery();
  // const { mutateAsync: updateVipMutate } = useUpdateVipMutation();
  const [vipStatus, setVipStatus] = useState(isVip);

  useEffect(() => {
    if (user) {
      socket.emit("login", {
        userId: user.id,
      });
    }
  }, [user]);

  useEffect(() => {
    if (isVip) {
      socket.emit("disconnect_vip_rent_order", {
        userId: user.id,
      });
      setVipStatus(isVip);
    }
  }, [isVip]);

  // const handleUpdateVip = async (userId, isVip) => {
  //   try {
  //     const res = await updateVipMutate({ userId, isVip });
  //     if (res) {
  //       getMeRefetch();

  //       if (window.electron.sendToMain) {
  //         window.electron.sendToMain(electronConstants.LOG_OUT_STEAM);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error updating VIP status:", error);
  //   }
  // };

  useEffect(() => {
    if (userData) {
      if (vipData && user && vipStatus && !isVip) {
        setVipStatus(isVip);

        if (window.electron.sendToMain) {
          window.electron.sendToMain(electronConstants.LOG_OUT_STEAM);
        }

        socket.emit("disconnect_vip_rent_order", {
          userId: user.id,
        });
      }

      dispatch(getMe(userData));
    }
  }, [userData, user, vipData, isVip, vipStatus]);

  return isLoading ? (
    <LoadingVideo loading={true} />
  ) : (
    <>
      <Header
        navItems={[
          { title: "Thuê TK Game", link: "/chitiet" },
          { title: "Kho TK Game", link: "/quanlythue" },
        ]}
      />
      <div className="h-screen overflow-y-auto">
        <Outlet />
      </div>
    </>
  );
};

export default memo(Layout);
