// StateContext.js
import { createContext } from "react";
import { contextConstant } from "./constant";

// Initial state
export const initialState = {
  user: null,
};

// Create context
export const StateContext = createContext();

// Reducer function
export const reducer = (state, action) => {
  switch (action.type) {
    case contextConstant.GET_ME:
      return {
        ...state,
        ...action.payload,
      };
    case contextConstant.GET_RENTS_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          rentStatus: action.payload,
        },
      };
    case contextConstant.GET_TIMES_LEFT:
      return {
        ...state,
        user: {
          ...state.user,
          timesLeft: action.payload,
        },
      };
    default:
      return state;
  }
};
