import { useSearchParams } from "react-router-dom";
import CheckIcon from "../assets/icons/detail/Check.svg";
import TransactionErrIcon from "../assets/icons/detail/TransactionErr.svg";
import { electronConstants } from "../constant";
import { useUpdatePaymentOrderStatusMutation } from "../store/mutations/payment/payment.mutate";
import { useEffect } from "react";
const SuccessPage = () => {
  const { mutateAsync } = useUpdatePaymentOrderStatusMutation();
  const [searchParams] = useSearchParams();
  const transactionID = searchParams.get("vnp_TxnRef");
  const transactionStatus = searchParams.get("vnp_TransactionStatus");
  const cardType = searchParams.get("vnp_CardType");
  const isSuccess = transactionStatus === "00";

  useEffect(() => {
    if (isSuccess && transactionID) {
      mutateAsync({ orderId: JSON.parse(transactionID), cardType: cardType });
    }
  }, [isSuccess]);

  return (
    <div className="size-full flex justify-center items-center flex-col gap-4">
      <img
        className="checked"
        src={isSuccess ? CheckIcon : TransactionErrIcon}
        alt="check icon"
      />
      <h2 className="text-2xl">
        {isSuccess
          ? `Giao dịch hoàn tất, bấm xác nhận đề quay trở về ứng dụng`
          : `Giao dịch thất bại, vui lòng thử lại sau`}
      </h2>
      <a href={electronConstants.RELOAD_PROTOCOL_LINK} className="">
        <button className="bg-[#0e6fad] text-[#ffffff] px-3 py-2 rounded-xl hover:opacity-70">
          Xác Nhận
        </button>
      </a>
    </div>
  );
};

export default SuccessPage;
