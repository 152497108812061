import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createPinOrder,
  createRentOrder,
  rentAccGame,
  updatePinOrder,
  upPinOrder,
} from "../../apis/orders";
import { queryContants } from "../../constant";

export const useRentAccMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: rentAccGame,
    onSuccess: () => {
      queryClient.invalidateQueries(queryContants.GET_ME);
    },
  });
};

export const useCreateRentOrderMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createRentOrder,
    onSuccess: () => {
      queryClient.invalidateQueries(queryContants.GET_ME);
    },
  });
};
export const useCreatePinOrderMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createPinOrder,
    onSuccess: () => {
      queryClient.invalidateQueries(queryContants.GET_ALL_PIN_ORDER);
    },
  });
};
export const useUnpinOrderMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: upPinOrder,
    onSuccess: () => {
      queryClient.invalidateQueries(queryContants.GET_ALL_PIN_ORDER);
    },
  });
};
export const useUpdatePinOrderMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updatePinOrder,
    onSuccess: () => {
      queryClient.invalidateQueries(queryContants.GET_ALL_PIN_ORDER);
    },
  });
};
