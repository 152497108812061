import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Image, Row, Tooltip } from "antd";
import { useAllWishlistQuery } from "../store/queries/game/wishlist.query.js";
import { handleScrollVertical } from "../utils/scrollHandle.js";
import { LoadingIcon } from "../assets/icons/index.js";
import iconSteam from "../assets/icons/home/Steam_icon_logo 1.png";
import iconGame from "../assets/icons/home/joystick 1.png";
import iconHeart from "../assets/icons/home/heart.png";
import iconPriceTag from "../assets/icons/price-tag 1.png";
import iconPurchased from "../assets/icons/home/joystick 1.png";
import iconRented from "../assets/icons/detail/key 2.png";
import iconPinned from "../assets/icons/detail/paper-pin 2.png";
import dayjs from "dayjs";
import { Statistic } from "antd";
import SearchIcon from "../assets/icons/searchIcon.png";
import { debounce } from "lodash";
import UserPanel from "../components/Sidebar/UserPanel.js";
import iconWarning from "../assets/icons/orderlist/warning.png";
import { useNavigate } from "react-router-dom";
import { useWishlistMutation } from "../store/mutations/game/wishlist.mutate.js";
const { Countdown } = Statistic;

const mygameTypes = {
  WISHLIST: "wishlist",
  BOUGHT: "bought",
  RENTING: "renting",
  PINNED: "pinned",
};

const OrdersList = () => {
  const [activeFilter, setActiveFilter] = useState("Game đã mua");
  const [gameType, setGameType] = useState(mygameTypes.BOUGHT);
  const [searchParams, setSearchParams] = useState("");
  const navigate = useNavigate();

  const {
    data: allWishlistData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
    refetch,
  } = useAllWishlistQuery(gameType, 10, searchParams);
  const { mutateAsync } = useWishlistMutation();

  const debouncedSearch = useCallback(
    debounce((nextValue) => {
      setSearchParams(nextValue);
    }, 500)
  );

  const handleChangeValue = async (value) => {
    await debouncedSearch(value);
  };

  const handleToggleWishlist = (item) => {
    mutateAsync({ steamId: item.id, isWishlist: !item.isWishlist });
  };

  const formatCurrency = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };
  useEffect(() => {
    if (!allWishlistData || allWishlistData.length === 0) return;

    allWishlistData.forEach((item) => {
      if (item.time_left) {
        return setTimeout(() => {
          refetch();
        }, item.time_left * 1000 + 1000);
      }
    });
  }, [allWishlistData]);

  useEffect(() => {
    let type;
    switch (activeFilter) {
      case "Game đã mua":
        type = mygameTypes.BOUGHT;
        break;
      case "Danh sách yêu thích":
        type = mygameTypes.WISHLIST;
        break;
      case "Đang thuê":
        type = mygameTypes.RENTING;
        break;
      case "Tài khoản đã ghim":
        type = mygameTypes.PINNED;
        break;
      default:
        type = mygameTypes.BOUGHT;
        break;
    }
    setGameType(type);
  }, [activeFilter]);

  useEffect(() => {
    refetch();
  }, [gameType, refetch, searchParams]);

  const handleNavigation = (link) => {
    navigate(link);
  };

  const renderContent = () => {
    let dataToRender = allWishlistData || [];

    if (isError || dataToRender.length === 0) {
      switch (activeFilter) {
        case "Game đã mua":
          return (
            <div className="text-center text-gray-300 text-2xl mt-32">
              <div>Bạn chưa mua game nào :v</div>
              <div>
                Nếu thấy game hay thì hãy mua game để ủng hộ nhà phát triển
                nhé!!
              </div>
            </div>
          );
        case "Danh sách yêu thích":
          return (
            <div className="text-center text-gray-300 text-2xl mt-32">
              <div>Hiện chưa có sản phẩm yêu thích nào!</div>
            </div>
          );
        case "Đang thuê":
          return (
            <div className="text-center text-gray-300 text-2xl mt-32">
              <div>Hiện chưa có tài khoản đang thuê nào!</div>
            </div>
          );
        case "Tài khoản đã ghim":
          return (
            <div className="text-center text-gray-300 text-2xl mt-32">
              <div>Hiện chưa có tài khoản đã ghim nào!</div>
            </div>
          );
        default:
          return null;
      }
    }

    return (
      <Row gutter={[16, 16]} justify="start">
        {dataToRender.map((item) => (
          <Col className="h-fit" xl={6} lg={8} sm={12} xs={24} key={item.id}>
            <div className="relative bg-radiant-gradient h-full rounded-lg">
              <div className="relative w-full sm:h-36 md:h-40 h-44 overflow-hidden rounded-tl-lg rounded-tr-lg">
                <div
                  onClick={() => handleNavigation(`/xemgame/${item.id}`)}
                  className="w-full h-full cursor-pointer"
                >
                  <img
                    src={item.images[0]}
                    alt=""
                    className="object-cover w-full h-full transform transition-transform duration-300 hover:scale-110"
                  />
                </div>
              </div>
              <div className="sm:p-2 md:p-3 lg:p-5">
                <div className="flex flex-wrap justify-between items-start gap-1.25 pb-3 border-b border-white/50">
                  <div className="flex items-center">
                    <div className="sm:h-7 sm:w-7 md:h-9 md:w-9 h-10 w-10 flex items-center">
                      <Image alt="" src={iconSteam} preview={false} />
                    </div>
                    <div className="sm:pl-1 pl-3">
                      <p className="sm:text-[12px] md:text-[14px] text-white text-base">
                        Nền tảng
                      </p>
                      <span className="sm:text-[11px] md:text-[14px] text-base">
                        Steam
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="sm:h-7 sm:w-7 md:h-9 md:w-9 h-10 w-10 flex items-center">
                      <Image alt="" src={iconGame} preview={false} />
                    </div>
                    <div className="sm:pl-1 pl-3">
                      <p className="sm:text-[12px] md:text-[14px] text-white text-base">
                        Chế độ
                      </p>
                      <span className="sm:text-[11px] md:text-[14px] text-base">
                        {item.chedo}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-3 flex items-center">
                  <div className="sm:min-h-12 md:min-h-14 min-h-16">
                    <h4 className="text-[22px] sm:text-base md:text-xl">
                      <div
                        onClick={() => handleNavigation(`/xemgame/${item.id}`)}
                        className="hover:text-blue-400 cursor-pointer line-clamp-2"
                      >
                        {item.tengame}
                      </div>
                    </h4>
                    {item.time_left && (
                      <div className="flex justify-between items-center mt-2">
                        <div className="md:text-[13px] text-[12px]">
                          Thời gian thuê còn:
                        </div>
                        <div className="rent-countdown">
                          <Countdown
                            className="lg:ml-5 sm:px-2 md:pl-6 lg:px-5"
                            value={dayjs().add(item.time_left, "seconds")}
                            format="HH Giờ mm phút"
                            valueStyle={{ color: "#3f8600" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div></div>
                  <div>
                    <Button
                      onClick={() => handleToggleWishlist(item)}
                      size="small"
                      className={`rounded-3xl text-base ${
                        item.isWishlist
                          ? "text-red-500 bg-blue-950"
                          : "bg-blue-950 text-white"
                      }`}
                      data-id={item.id}
                      icon={<i className="fas fa-heart" />}
                    >
                      <span className="text-white wishlistCount sm:text-sm md:text-md lg:text-lg">
                        {item.numberWishlist ?? 0}
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="flex justify-between items-center pt-2">
                  <div className="flex items-center">
                    <div className="sm:h-6 sm:w-6 md:h-8 md:w-8 lg:h-10 lg:w-10 flex items-center">
                      <Image src={iconPriceTag} alt="" preview={false} />
                    </div>
                    <span className="text-base text-white font-semibold sm:text-sm md:text-md lg:text-lg">
                      Giá thuê
                    </span>
                  </div>
                  <div className="">
                    <span className="text-base font-semibold text-[#7ED0FF] sm:text-sm md:text-md lg:text-lg">
                      {formatCurrency(item.giathue)}đ/giờ
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  const filterIcons = {
    "Game đã mua": iconPurchased,
    "Danh sách yêu thích": iconHeart,
    "Đang thuê": iconRented,
    "Tài khoản đã ghim": iconPinned,
  };

  return (
    <div
      onScroll={(e) =>
        handleScrollVertical(e, fetchNextPage, hasNextPage, isFetchingNextPage)
      }
      className="flex h-screen overflow-x-hidden overflow-scroll pt-[92px] w-full px-2 sm:px-10 md:px-14 xs:px-6 box-border"
    >
      <UserPanel
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        filterIcons={filterIcons}
      />
      <div className=" justify-center flex-1 ml-12 pt-10 lg:mx-10">
        <div className="flex justify-between items-center">
          <div className="flex justify-center">
            <h2 className="text-white font-normal md:text-xl sm:text-xl xs:text-sm mx-2">
              {activeFilter}
            </h2>
            {activeFilter === "Game đã mua" && (
              <Tooltip
                placement="topLeft"
                trigger="hover"
                title={
                  "Sau khi mua game, game sẽ vĩnh viễn thuộc về bạn và bạn có thể chơi bất cứ khi nào mà không cần phải chờ thuê tài khoản game"
                }
              >
                <img
                  src={iconWarning}
                  alt="warning"
                  className="md:h-6 md:w-6 sm:w-6 sm:h-6 cursor-pointer xs:h-4 xs:w-4 opacity-70 hover:opacity-100 transition-opacity duration-300"
                />
              </Tooltip>
            )}
            {activeFilter === "Tài khoản đã ghim" && (
              <Tooltip
                placement="topLeft"
                trigger="hover"
                title={
                  "Tài khoản sẽ tự động gỡ ghim sau 7 ngày nếu như bạn không đăng nhập và sử dụng"
                }
              >
                <img
                  src={iconWarning}
                  alt="pinned"
                  className="md:h-6 md:w-6 sm:w-6 sm:h-6 cursor-pointer xs:h-4 xs:w-4 opacity-70 hover:opacity-100 transition-opacity duration-300"
                />
              </Tooltip>
            )}
          </div>
          <div className="flex justify-between items-center md:w-60 sm:w-[14rem] xs:w-[12rem] lg:w-[16rem] p-2 rounded-lg bg-[#242333] border border-gray-500 text-sm">
            <input
              onChange={(e) => handleChangeValue(e.target.value)}
              placeholder="Tìm kiếm"
              className="bg-transparent h-full w-full"
            />
            <img src={SearchIcon} alt="search" className="h-4 w-4" />
          </div>
        </div>
        {isLoading ? (
          <Col className="flex justify-center items-center h-[20vh]" span={24}>
            <img className="spinner" src={LoadingIcon} alt="Loading..." />
          </Col>
        ) : (
          <div className="Nếu thấy game hay thì hãy mua game để ủng hộ nhà phát triển nhé!! pt-4">
            {renderContent()}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrdersList;
