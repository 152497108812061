import React from "react";
import { StarFilled } from "@ant-design/icons";

const DescriptionWithStars = ({ text }) => {
  return (
    <p className="sm:text-[14px] font-[600] lg:text-lg text-white flex items-start">
      <StarFilled className="mr-2 mt-1  text-yellow-400" />
      {text}
    </p>
  );
};

export default DescriptionWithStars;
