import { debounce } from "lodash";

export const handleScrollVertical = (
  e,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage
) => {
  // Persist the event or extract necessary properties
  const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
  handleScroll(
    { scrollHeight, scrollTop, clientHeight },
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  );
};

const handleScroll = debounce(
  (
    { scrollHeight, scrollTop, clientHeight },
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  ) => {
    const isAtBottom =
      Math.round(scrollHeight) - Math.round(scrollTop) <=
      Math.round(clientHeight) + 60;
    if (isAtBottom && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  },
  100
);
