import {
  getVipRewardStatus,
  getHistoryPayment,
  getVipOrder,
} from "../../../apis/payment/payment";
import { useQuery } from "@tanstack/react-query";
import { useInfinitePageQuery } from "../infinitePage.query";
import { queryContants } from "../../../constant";

export const useHistoryPaymentQuery = (pageSize, userId) => {
  return useInfinitePageQuery({
    queryKey: [queryContants.GET_PAYMENT_HISTORY],
    queryFn: (props) => getHistoryPayment(props, pageSize, userId),
    enabled: !!userId,
  });
};

export const useAmountHistoryQuery = ({ userId, vipData }) => {
  return useQuery({
    queryKey: [queryContants.GET_AMOUNT_HISTORY],
    queryFn: () => getVipRewardStatus({ userId, vipData }),
    enabled: !!userId && !!vipData,
  });
};

export const useGetVipOrderQuery = ({ userId }) => {
  return useQuery({
    queryKey: [queryContants.GET_VIP_ORDER],
    queryFn: () => getVipOrder({ userId }),
    enabled: !!userId,
  });
};