import React, { useContext } from "react";
import { Tabs } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import HistoryPayment from "../components/Common/HistoryPayment/HistoryPayment";
import UpgradeVIP from "../components/Common/UpdateVIP/UpdateVIP";
import AccountInfo from "../components/Common/AccountInfo/AccountInfo";
import TimeVip from "../components/Common/UpdateVIP/TimeVip";

import infoIcon from "../assets/icons/header/identification 1.png";
import diamondIcon from "../assets/icons/header/diamond.png";
import billIcon from "../assets/icons/header/bill 1.png";
import { StateContext } from "../context/reducer";

const AccountPage = () => {
  const { tab } = useParams();
  const {
    state: { user },
  } = useContext(StateContext);
  const navigate = useNavigate();

  const handleTabChange = (key) => {
    navigate(`/account/${key}`);
  };

  const tabItems = [
    { key: "1", label: "Thông tin tài khoản", icon: infoIcon },
    { key: "2", label: "Nâng cấp VIP", icon: diamondIcon },
    { key: "3", label: "Lịch sử giao dịch", icon: billIcon },
  ];

  return (
    <div className="p-6 flex justify-center space-x-4 lg:min-h-[640px] overflow-y-auto pt-32">
      <div className="sm:max-w-[28%] xs:max-w-[30%] sm:pt-2 max-w-[20%] h-[240px] bg-[#242333] rounded-lg">
        <Tabs
          tabPosition="left"
          activeKey={tab || "1"}
          onChange={handleTabChange}
          className="custom-tabs"
          items={tabItems.map((item, index) => ({
            key: item.key,
            label: (
              <span
                className={`tab-item font-semibold min-w-56 py-4 flex items-center text-white ${
                  tab === item.key ? "opacity-100" : "opacity-70"
                } hover:opacity-100 text-base p-2 ${
                  index < tabItems.length - 1 ? "border-b border-gray-600" : ""
                }`}
              >
                <img src={item.icon} alt="" className="h-8 w-8 mr-2" />
                {item.label}
              </span>
            ),
          }))}
        />
        {user?.is_vip !== "0" && <TimeVip />}
      </div>
      <div
        className={`xs:w-[50%] md:w-[60%] sm:w-[65%] lg:w-[965px] ${
          tab === "3" ? "h-[70vh]" : "h-full"
        } bg-[#242333] px-4 pt-4 pb-8 rounded-lg`}
      >
        {tab === "1" && <AccountInfo />}
        {tab === "2" && <UpgradeVIP />}
        {tab === "3" && <HistoryPayment />}
      </div>
    </div>
  );
};

export default AccountPage;
