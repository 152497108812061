import axiosServer from "../axios/axiosServer";
import APIs from "../constant/endpoint";

export const getAllMyOders = async () => {
  const res = await axiosServer.get(APIs.getAllOders);
  return res.data;
};

export const rentAccGame = async (data) => {
  const res = await axiosServer.post(APIs.rentAccGame, data);
  return res.data;
};

export const createRentOrder = async (data) => {
  const res = await axiosServer.post(APIs.createRentOrder, data);
  return res.data;
};

export const getAccountOrder = async (data) => {
  const res = await axiosServer.post(APIs.getAccGameOrder, data);
  return res.data;
};

export const getPinAndBuyOrder = async (data) => {
  const res = await axiosServer.get(APIs.getPinAndBuyOrder, { params: data });
  return res.data;
};

export const createPinOrder = async (data) => {
  const res = await axiosServer.post(APIs.createPinOrder, data);
  return res.data;
};
export const upPinOrder = async (data) => {
  const res = await axiosServer.post(APIs.unpinOrder, data);
  return res.data;
};
export const updatePinOrder = async (data) => {
  const res = await axiosServer.post(APIs.updatePinOrder, data);
  return res.data;
};
