import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../context/reducer.js";
import { Row } from "antd";
import {
  useGetFreeListGameQuery,
  useGetMostWishListGameQuery,
} from "../store/queries/game/game.query.js";
import { useWishlistMutation } from "../store/mutations/game/wishlist.mutate.js";
import Footer from "../components/Footer/Footer.js";
import CardFlex from "../components/Card/CardFlex.js";
import {
  facebookIcon,
  discordIcon,
  youtubeIcon,
} from "../assets/icons/home/index";

import videoBanner from "../assets/video/khuyenmaibanner.mp4";

const TrangChu = () => {
  const { state } = useContext(StateContext);
  const { user } = state;
  const { mutateAsync } = useWishlistMutation();
  const { data: mostWishlistData } = useGetMostWishListGameQuery();
  const { data: freeGameData } = useGetFreeListGameQuery();
  const navigate = useNavigate();

  const handleToggleWishlist = (item) => {
    mutateAsync({ steamId: item.id, isWishlist: !item.isWishlist });
  };
  const formatCurrency = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const handleSendToMain = (action, data) => {
    if (window.electron.sendToMain) {
      window.electron.sendToMain(action, data);
    }
  };

  const handleOpenLinkContact = (link) => {
    handleSendToMain("openExternalLink", link);
  };

  const handleNavigation = (link) => {
    navigate(link);
  };

  return (
    <div className={`h-full overflow-y-auto ${user ? "pt-[92px]" : ""}`}>
      <section className="relative z-[1] overflow-hidden">
        {/* Video Background */}
        <video
          autoPlay
          loop
          muted
          className="inset-0 w-full h-full object-cover"
          src={videoBanner}
        ></video>

        {/* Content */}
        <div className="container relative z-10 xs:-top-40 sm:-top-40 md:-top-60 lg:-top-[20rem]">
          <div className="row xs:ml-6 sm:ml-6 md:ml-10 lg:ml-14 justify-content-center">
            <div className="col-lg-6">
              <div className="p-0">
                {/* Removed the heading and description */}
                <div>
                  {/* {user ? (
                    <Link
                      to="/chitiet"
                      className="rounded-full btn--warning text-white pill font-medium lg:px-8 lg:py-6 lg:text-2xl md:px-6 md:py-4 md:text-xl sm:text-lg sm:px-6 sm:py-3 xs:py-3 xs:px-5"
                    >
                      {" "}
                      <span className="icon">
                        <i className="fas fa-rocket" />
                      </span>{" "}
                      Khám Phá
                    </Link>
                  ) : (
                    <div>
                      <Link
                        to="/chitiet"
                        className="btn btn--warning text-white pill font-medium "
                      >
                        {" "}
                        <span className="icon">
                          <i className="fas fa-rocket" />
                        </span>{" "}
                        Khám Phá
                      </Link>
                      <Link to="/login" className="btn btn--dark pill ml-2">
                        {" "}
                        <span className="icon">
                          <i className="fas fa-rocket" />
                        </span>{" "}
                        Đăng nhập
                      </Link>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {user && (
        <section className="product-explore">
          <div className="confirmation-loader">
            <div className="cv-spinner">
              <span className="spinner" />
            </div>
          </div>
          <div className="confirmation-loader-2 overlay-2" />
          <div>
            <div className="mb-4 flex items-center justify-between px-14">
              <h2 className="text-[20px] lg:text-[2rem] md:text-[1.5rem]">
                Xu hướng
              </h2>
              <div
                onClick={() => handleNavigation("/quanlythue")}
                className="opacity-70 cursor-pointer hover:opacity-100 text-white"
              >
                Xem thêm
              </div>
            </div>
            <div className="px-4">
              <Row gutter={[16, 16]} justify="center">
                <CardFlex
                  games={mostWishlistData}
                  onToggleWishlist={handleToggleWishlist}
                  formatCurrency={formatCurrency}
                />
              </Row>
            </div>
          </div>
        </section>
      )}
      {user && (
        <section className="featured pt-[5rem] mb-[12rem]">
          <div className="w-full">
            <div className="mb-4 flex items-center justify-between px-14">
              <h2 className="text-[20px] lg:text-[2rem] md:text-[1.5rem]">
                Game miễn phí
              </h2>
              <div
                onClick={() => {
                  handleNavigation("/quanlythue");
                }}
                className=" text-white opacity-70 hover:opacity-100"
              >
                Xem thêm
              </div>
            </div>
            <div className="px-4">
              <Row gutter={[16, 16]} justify="center">
                <CardFlex
                  games={freeGameData}
                  onToggleWishlist={handleToggleWishlist}
                  formatCurrency={formatCurrency}
                />
              </Row>
            </div>
          </div>
        </section>
      )}
      {user && (
        <div className="flex flex-col fixed right-6 bottom-5 z-10 ">
          <div
            onClick={() =>
              handleOpenLinkContact("https://www.youtube.com/@kiancharo")
            }
            className="cursor-pointer"
          >
            <img
              src={youtubeIcon}
              alt="youtube"
              className="w-[1.5rem] h-[1.5rem] mt-5 opacity-70 hover:opacity-100 transition-opacity duration-300"
            />
          </div>
          <div
            onClick={() =>
              handleOpenLinkContact(
                "https://www.facebook.com/groups/cyberkingvietnam"
              )
            }
            className="cursor-pointer"
          >
            <img
              src={facebookIcon}
              alt="facebook"
              className="w-[1.5rem] h-[1.5rem] mt-5 opacity-70 hover:opacity-100 transition-opacity duration-300"
            />
          </div>
          <div
            onClick={() =>
              handleOpenLinkContact("https://discord.gg/TcqaEq2RnJ")
            }
            className="cursor-pointer"
          >
            <img
              src={discordIcon}
              alt="discord"
              className="w-[1.5rem] h-[1.5rem] mt-5 opacity-70 hover:opacity-100 transition-opacity duration-300"
            />
          </div>
        </div>
      )}
      {/* fotter */}
      {user && (
        <article className="gallery">
          <img
            src="https://steamuserimages-a.akamaihd.net/ugc/775097847622680603/EC3BCD491FBC276850A384E43515C34BE324DA81/?imw=637&imh=358&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=true"
            alt="guitar player at concert"
          />
          <img
            src="https://wallpapers.com/images/featured/gta-5-qpjtjdxwbwrk4gyj.jpg"
            alt="duo singing"
          />
          <img
            src="https://steamuserimages-a.akamaihd.net/ugc/931554217225615451/A0282B56D929E82A7F511E134C67815E8C7F6C56/?imw=637&imh=358&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=true"
            alt="crowd cheering"
          />
          <img
            src="https://cdn.cloudflare.steamstatic.com/steam/apps/471710/capsule_616x353.jpg?t=1701282286"
            alt="singer performing"
          />
          <img
            src="https://cdn.cloudflare.steamstatic.com/steam/apps/2195250/capsule_616x353.jpg?t=1709567756"
            alt="singer fistbumping crowd"
          />
          <img
            src="https://www.konami.com/efootball/s/img/share/efootball2024_mainvisual_messi.jpg"
            alt="man with a guitar singing"
          />
          <img
            src="https://i.ytimg.com/vi/E3Huy2cdih0/sddefault.jpg"
            alt="crowd looking at a lighted stage"
          />
          <img
            src="https://cdn.steamstatic.com/steamcommunity/public/images/items/730/afc0ee6b14f1dd99e8407eaa963edc184ef16e67.jpg"
            alt="woman singing on stage"
          />
        </article>
      )}
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n   \n\n.gallery {\n    --size: 100px;\n  display: grid;\n  grid-template-columns: repeat(6, var(--size));\n  grid-auto-rows: var(--size);\n  margin-bottom: var(--size);\n  place-items: start center;\n  gap: 5px;\n  justify-content: center; /* Căn giữa theo chiều ngang */\n  align-items: center; /* Căn giữa theo chiều dọc */\n  \n  &:has(:hover) img:not(:hover),\n  &:has(:focus) img:not(:focus){\n    filter: brightness(0.5) contrast(0.5);\n  }\n\n  & img {\n    object-fit: cover;\n    width: calc(var(--size) * 2);\n    height: calc(var(--size) * 2);\n    clip-path: path("M90,10 C100,0 100,0 110,10 190,90 190,90 190,90 200,100 200,100 190,110 190,110 110,190 110,190 100,200 100,200 90,190 90,190 10,110 10,110 0,100 0,100 10,90Z");\n    transition: clip-path 0.25s, filter 0.75s;\n    grid-column: auto / span 2;\n    border-radius: 5px;\n\n    &:nth-child(5n - 1) { \n      grid-column: 2 / span 2 \n    }\n\n    &:hover,\n    &:focus {\n      clip-path: path("M0,0 C0,0 200,0 200,0 200,0 200,100 200,100 200,100 200,200 200,200 200,200 100,200 100,200 100,200 100,200 0,200 0,200 0,100 0,100 0,100 0,100 0,100Z");\n      z-index: 1;\n      transition: clip-path 0.25s, filter 0.25s;\n    }\n    \n    &:focus {\n      outline: 1px dashed black;\n      outline-offset: -5px;\n    }\n  }\n}\n  ',
        }}
      />
      <link
        rel="stylesheet"
        href="https://script.viserlab.com/nftlab/assets/global/css/iziToast.min.css"
      />
      {/* Google tag (gtag.js) */}
      <Footer />
    </div>
  );
};

export default TrangChu;
