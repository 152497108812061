import React, { useState, useEffect } from "react";
import happyIcons from "../../assets/icons/popup/Icon blue popup.png";
import { Image } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const PopupConfirm = ({
  className,
  visible,
  onClose,
  title,
  content,
  depcription,
  buttons = [],
}) => {
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (visible) {
      setShowAnimation(true);
    } else {
      setTimeout(() => setShowAnimation(false), 300);
    }
  }, [visible]);

  if (!visible && !showAnimation) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <main
        className={`max-h-[400px] gap-4 min-w-[500px] max-w-xs transition-transform duration-300 
                ${visible ? "animate-fadeIn" : "animate-fadeOut"}
                [&>article]:bg-[#b5f9ff]
                [&>article]:shadow-xl
                [&>article]:rounded-xl
                [&>article]:overflow-hidden
                [&>article]:relative 
                [&>article]:isolate
                [&>article]:transition-all
                [&>article]:duration-300
                [&>article>hgroup]:px-12
                [&>article>hgroup]:py-8
                [&>article>main]:px-12
                [&>article>main]:py-8
              
                [&_.waves]:absolute     
                [&_.waves]:inset-[-35%_0_0_0]
                [&_.waves]:text-white
                [&_.waves]:-z-10
                [&_.waves]:rounded-b-xl
                [&_.waves]:overflow-hidden
                [&_.waves]:transition-all
                [&_.waves]:duration-500
                [&_svg]:transition-all
                [&_svg]:duration-300
                ${className}`}
      >
        <article className="group relative rounded-lg shadow-lg min-h-[320px] min-w-full overflow-hidden flex flex-col items-center justify-center">
          <div className="waves group-hover:inset-[-50%_-20%_0_-40%]">
            <svg
              className="w-full h-full"
              viewBox="400 0 550 1100"
              preserveAspectRatio="none"
              overflow="auto"
              shapeRendering="auto"
              fill="currentColor"
            >
              <path
                id="wavepath"
                d="M 0 2000 0 500 Q 200 600 300 500 t 300 0 400 -200 400 -300 400 -300 v1000 z"
                fill="white"
              />
            </svg>
          </div>
          <button
            className="absolute text-2xl top-0 right-2 text-[#1BBFEE] opacity-70 hover:opacity-100"
            onClick={onClose}
          >
            <CloseOutlined />
          </button>
          <div className="flex justify-center">
            <div className="px-4 pt-3 size-[40%]">
              <Image src={happyIcons} alt="" preview={false} />
            </div>
          </div>
          <div className="px-4 py-3 flex justify-center">
            <h3 className="text-[26px] text-[#65C4DF] font-medium">{title}</h3>
          </div>
          <div className="flex justify-center">
            <h3 className="text-lg text-black font-medium max-w-[400px] text-center">
              {content}
            </h3>
          </div>
          <div className="flex justify-center">
            <h3 className="text-lg text-black font-medium">{depcription}</h3>
          </div>
          <div className="px-4 py-4 flex justify-center space-x-2">
            {buttons.map((button, index) => (
              <button
                key={index}
                className={button.className}
                onClick={button.onClick}
              >
                {button.label}
              </button>
            ))}
          </div>
        </article>
      </main>
    </div>
  );
};

export default PopupConfirm;
