import React from "react";
import { Image } from "antd";

const PaymentMethodCard = ({
  method,
  icon,
  title,
  description,
  isSelected,
  onClick,
  children,
}) => {
  return (
    <div
      className={`bg-[#3C3C56] text-white py-2 px-6 rounded-xl ${
        isSelected ? "ring-2" : ""
      }`}
    >
      <div className="flex items-center cursor-pointer" onClick={onClick}>
        <div className="h-[62px] w-[62px]">
          <Image src={icon} alt={method} preview={false} className="mr-4" />
        </div>
        <div className="ml-4">
          <div className="font-semibold">{title}</div>
          <div className="text-gray-400">{description}</div>
        </div>
      </div>
      
      <div
        className={`transition-all duration-500 ease-in-out overflow-hidden ${
          isSelected ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <hr className="my-4 border-gray-600" />
        <div className="mt-4">{children}</div>
      </div>
    </div>
  );
};

export default PaymentMethodCard;
