import React, { useContext, useEffect, useState } from "react";
import { useGetVipQuery } from "../../../store/queries/auth.query";
import { StateContext } from "../../../context/reducer";
import { useGetVipOrderQuery } from "../../../store/queries/payment/payment.query";
import { Statistic } from "antd";
import dayjs from "dayjs";

const { Countdown } = Statistic;

const TimeVip = () => {
  const { state } = useContext(StateContext);
  const { user } = state;
  const { data: vipData } = useGetVipQuery();

  const { data: vipOrderData } = useGetVipOrderQuery({
    userId: user?.id,
  });

  const [endTimeVip, setEndTimeVip] = useState(null);

  const getVipRewardStatus = (isVip) => {
    const vip = vipData?.find((item) => item.id === JSON.parse(isVip));
    return (
      vip && (
        <div
          className={`flex items-center ${vip.color} bg-clip-text text-fill-transparent`}
        >
          <div>{vip.name}</div>
          <img src={vip.img} alt={vip.name} className="h-6 w-6 ml-2" />
        </div>
      )
    );
  };

  useEffect(() => {
    if (user?.is_vip && vipOrderData) {
      const startAt = dayjs(vipOrderData.data.start_at);
      const durationDays = vipOrderData.data.times;
      const calculatedEndTime = startAt.add(durationDays, "day");
      setEndTimeVip(calculatedEndTime);
    } else {
      setEndTimeVip(0);
    }
  }, [user, vipOrderData]);

  return (
    <div className="mt-7">
      <div
        className={`bg-[#2E2C3E] rounded-lg p-4 flex flex-col items-start text-white`}
        style={{ border: "1px solid #91A8F0" }}
      >
        <div>
          <span
            className="text-base font-bold"
            style={{
              background: "linear-gradient(to right, #D3E8FF, #7C93FF)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            {user?.is_vip && getVipRewardStatus(user?.is_vip)}
          </span>
        </div>
        <div className="flex items-center text-base font-semibold">
          <span>Thời gian còn lại:</span>
          <div className="custom-countdown ml-2">
            <Countdown value={endTimeVip} format="D [Ngày] H [Giờ]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeVip;
