import "./App.css";
import React, { useContext, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import {
  TrangChu,
  Login,
  Register,
  AutoLoginSteam,
  ChiTiet,
  XemGame,
  KhamPha,
  QuanLyThue,
  AccountPage,
  PaymentPage,
} from "./pages";
import Layout from "./components/Layout/Layout";
import Verify from "./pages/Verify";
import VerifyApp from "./pages/VerifyApp";
import { socket } from "./helpers/socket.helper";
import Swal from "sweetalert2";
import { getRockstarOTP } from "./apis/game/game";
import { isArray, isEmpty } from "lodash";
import SuccessPage from "./pages/SuccessPage";
import { StateContext } from "./context/reducer";
import { electronConstants } from "./constant";

export default function App() {
  const { state } = useContext(StateContext);
  const { user } = state;

  useEffect(() => {
    if (user && user.timesLeft) {
      const timerId = setTimeout(() => {
        if (user.rentStatus && window.electron.sendToMain) {
          window.electron.sendToMain(electronConstants.LOG_OUT_STEAM);
        }
      }, user.timesLeft * 1000);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [user]);

  useEffect(() => {
    socket.on("login_status", async (res) => {
      if (!res.isLogin) {
        const result = await Swal.fire({
          title: "Đăng nhập không thành công?",
          text: res.message,
          icon: "error",
          confirmButtonText: "Xác Nhận",
        });
        if (result.isConfirmed) {
          localStorage.removeItem("accessToken");
          window.electron.sendToMain &&
            window.electron.sendToMain("logout-user");
        } else {
          setTimeout(() => {
            localStorage.removeItem("accessToken");
            window.electron.sendToMain &&
              window.electron.sendToMain("logout-user");
          }, 5000);
        }
      }
    });
  }, [socket]);

  if (window?.electron?.receiveFromMain) {
    window.electron.receiveFromMain("sendOTPfromEmail", (data) => {
      if (data?.email)
        getRockstarOTP(data?.email)
          .then((res) => {
            if (!isEmpty(res.data))
              window.electron.sendToMain(
                "getOTPfromemail",
                isArray(res.data)
                  ? isEmpty(res.data)
                    ? undefined
                    : res.data[res.data.length - 1]
                  : res.data
              );
          })
          .catch((err) => {
            console.log("Lỗi khi lấy otp từ hệ thống", err);
          });
    });
  }

  return (<>
  <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<TrangChu />} />
          <Route path="/steam" element={<AutoLoginSteam />} />
          <Route path="/chitiet" element={<ChiTiet />} />
          <Route path="/xemgame/:id" element={<XemGame />} />
          <Route path="/khampha" element={<KhamPha />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/quanlythue" element={<QuanLyThue />} />
          <Route path="/account/:tab" element={<AccountPage />} />
        </Route>
        {/* {window.electron && (
          <> */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify/:email" element={<Verify />} />
        <Route path="/success" element={<SuccessPage />} />
        {/* </>
        )} */}
        <Route path="/forgot-password" element={<VerifyApp />} />
      </Routes>
    </BrowserRouter>
  </>
  );
}
