const Exit = ({ className }) => {
  return (
    <svg
      className={`size-[10px] fill-[#D9D9D9]  ${className}`}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.624999 0L9.99999 9.37498L9.37499 9.99998L0 0.624999L0.624999 0Z" />
      <path d="M1.28021e-05 9.375L9.375 2.37602e-05L10 0.625022L0.625012 10L1.28021e-05 9.375Z" />
    </svg>
  );
};

export default Exit;
