import axiosServer from "../axios/axiosServer";
import APIs from "../constant/endpoint";

export const login = async (data) => {
  const res = await axiosServer.post(APIs.login, data);
  return res.data;
};

export const getMe = async () => {
  const res = await axiosServer.get(APIs.getMe);
  return new Promise((resolve) => setTimeout(() => resolve(res.data), 100));
};
export const getVip = async () => {
  const res = await axiosServer.get(APIs.getVip);
  return new Promise((resolve) => setTimeout(() => resolve(res.data), 100));
};

export const checkValidVerifyToken = async (token) => {
  const res = await axiosServer.get(APIs.checkValidToken, {
    params: { token },
  });
  return res.data;
};

export const verifyOTP = async (otpData) => {
  const res = await axiosServer.post(APIs.verifyOTP, otpData);
  return new Promise((resolve) => setTimeout(() => resolve(res.data), 2000));
};

export const sendOTP = async (email) => {
  const res = await axiosServer.post(APIs.sendOTP, { email });
  return res.data;
};

export const changePasswordByOTP = async (data) => {
  const res = await axiosServer.post(APIs.changePasswordByOTP, data);
  return res.data;
};

export const updateVip = async (data) => {
  const res = await axiosServer.post(APIs.updateVip, data);
  return res.data;
};
