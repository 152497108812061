import React from "react";
import GameCard from "./GameCard";

const CardFlex = ({ games, onToggleWishlist, formatCurrency }) => {
    return (
        <div className="flex flex-wrap justify-start gap-6">
            {games?.map((game) => (
                <div key={game.id} className="max-w-[15rem] mx-auto">
                    <GameCard
                        game={game}
                        onToggleWishlist={onToggleWishlist}
                        formatCurrency={formatCurrency}
                    />
                </div>
            ))}
        </div>
    );
};

export default CardFlex;
