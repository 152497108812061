import React, { useContext } from "react";
import { useHistoryPaymentQuery } from "../../../store/queries/payment/payment.query";
import { StateContext } from "../../../context/reducer";

import { LoadingIcon } from "../../../assets/icons/index.js";
import { handleScrollVertical } from "../../../utils/scrollHandle.js";

const HistoryPayment = () => {
  const { state } = useContext(StateContext);
  const { user } = state;

  const {
    data: allHistoryPayment,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useHistoryPaymentQuery(20, user?.id);

  if (isLoading) {
    return (
      <img className="spinner mx-auto" src={LoadingIcon} alt="spin icon" />
    );
  }

  return (
    <div className="p-4 h-full" >
      <h2 className="flex justify-center text-2xl font-bold mb-4 text-white">
        Biến động số dư
      </h2>
      <div className="h-full">
        <div
          className="h-[80%] overflow-y-auto space-y-4 px-3"
          onScroll={(e) =>
            handleScrollVertical(
              e,
              fetchNextPage,
              hasNextPage,
              isFetchingNextPage
            )
          }
        >
          {!!allHistoryPayment &&
            !!allHistoryPayment.length &&
            allHistoryPayment.map((transaction, index) => (
              <div
                key={index}
                className="flex justify-between items-center border-b border-gray-500 pb-2"
              >
                <div className="text-white font-semibold">
                  {new Date(transaction.createdAt).toLocaleString()}
                </div>
                <div
                  className={`text-lg font-semibold ${transaction.payment_gate === "VNPay"
                    ? "text-green-400"
                    : "text-red-500"
                    }`}
                >
                  {transaction.payment_gate === "VNPay"
                    ? `+ ${parseFloat(transaction.amount).toLocaleString()} đ`
                    : `- ${parseFloat(transaction.amount).toLocaleString()} đ`}
                </div>
              </div>
            ))}
          {isFetchingNextPage && <div>Loading more...</div>}
          {!hasNextPage && !isFetchingNextPage && (
            <div>Không còn giao dịch nào nữa.</div>
          )}
        </div>
        {hasNextPage && (
          <div className="!mt-4 !mb-10">
            <img
              className="spinner mx-auto w-12 h-12"
              src={LoadingIcon}
              alt="spin icon"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoryPayment;
