import { useState } from "react";
import { useChangePasswordByOTPMutation } from "../../../store/mutations/auth.mutate";

const PasswordComfirmation = ({ email }) => {
  const [message, setMessage] = useState("");
  const [passwordreg, setPasswordReg] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { mutateAsync, data, isPending } = useChangePasswordByOTPMutation();

  const handleUpdatePassword = () => {
    if (passwordreg !== confirmPassword) setMessage("Mật khẩu không khớp");
    else {
      email &&
        mutateAsync({ password: passwordreg, email })
          .then(() => setMessage("Đổi mật khẩu thành công"))
          .catch((error) =>
            setMessage(error?.response?.data ?? "Đổi mật khẩu thất bại")
          );
    }
  };

  return (
    <div className="section text-center pt-16">
      {data?.message ? (
        <h4 className="pt-3 mb-3">{data.message}</h4>
      ) : (
        <h4 className="pt-3 mb-3">Nhập mật khẩu mới của bạn</h4>
      )}

      {data?.message ? (
        <></>
      ) : (
        <>
          <div className="px-8">
            <div className="form-group mt-2">
              <input
                type="password"
                value={passwordreg}
                className="form-style"
                onChange={(e) => {
                  setPasswordReg(e.target.value);
                  if (message) setMessage("");
                }}
                placeholder="Nhập mật khẩu..."
                autoComplete="off"
                required
              />
              <i className="input-icon uil uil-lock" />
            </div>
            <div className="form-group mt-2">
              <input
                type="password"
                value={confirmPassword}
                className="form-style"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  if (message) setMessage("");
                }}
                autoComplete="off"
                placeholder="Xác nhận mật khẩu..."
                required
              />
              <i className="input-icon uil uil-lock" />
            </div>
          </div>
          <button
            onClick={handleUpdatePassword}
            disabled={isPending}
            className={`btn mt-4 mb-2`}
          >
            {isPending ? "Đang xử lý..." : "Đổi mật khẩu"}
          </button>
          {message && <p className=" mt-1">{message}</p>}
        </>
      )}
    </div>
  );
};

export default PasswordComfirmation;
