import ReactDOM from "react-dom";
import { cyberLoading, cyberLoadingBorder } from "../../../assets/images";

function LoadingVideo({ className, loading }) {
  return ReactDOM.createPortal(
    <div
      className={`${
        loading ? "fixed" : "invisible"
      } before:bg-bgPrimary before:blur-2xl before:size-full before:absolute w-full h-full top-7 left-0 bg-transparent z-50 flex justify-center items-center ${className}`}
    >
      <div className="flex justify-center items-center relative w-96 h-96">
        <div className="size-52 bg-gradient-to-b from-[#171721] to-[#57575E]   rounded-full p-[30px] border-[0.35rem] border-[#8B8B90]">
          <img src={cyberLoading} alt="loading" />
          <img
            src={cyberLoadingBorder}
            className="absolute top-[13.5%] left-[13.5%] h-[73%] w-[73%] animate-spin"
            alt="loading"
          />
        </div>
      </div>
    </div>,
    document.getElementById("loading")
  );
}

export default LoadingVideo;
