const APIs = {
  // auth
  login: "/api/server/auth/login",
  getMe: "/api/server/user/getMe",
  getVip: "/api/server/user/get-vip",
  updateVip: "/api/server/user/update-vip",

  register: "/api/server/auth/register",
  checkValidToken: "/api/server/auth/checkValidToken",
  verifyOTP: "/api/server/auth/checkValidOTP",
  sendOTP: "/api/server/auth/sendOTP",
  changePasswordByOTP: "/api/server/auth/forgotPassword",

  //account
  changePassword: "/api/server/user/changePassword",

  // game
  getAllGame: "/api/server/games/getAllGame",
  getGameDetails: (id) => `/api/server/games/gamesteam/${id}`,
  getRockstarOTP: (email) => `/api/server/games/rockstarOTP/${email}`,

  //wishlist
  toggleWishlist: "/api/server/wishlist/toggleWishlist",
  mygame: (type) => `/api/server/wishlist/mygame/${type}`,
  getMostWishlistGame: "/api/server/wishlist/get-most-wish-list-game",
  getFreeGame: "/api/server/wishlist/get-free-game",

  //orders
  getAllRamdomGame: "/api/randomgame",
  rentAccGame: "/api/server/order/rent-acc-game",
  createRentOrder: "/api/server/order/create-rent-order",
  checkRentOrder: "/api/server/order/check-rent-order",
  getAccGameOrder: "/api/server/order/get-acc-game",
  getPinAndBuyOrder: "/api/server/order/get-pin-and-buy-order",
  createPinOrder: "/api/server/order/create-pin-order",
  unpinOrder: "/api/server/order/unpin-order",
  updatePinOrder: "/api/server/order/update-pin-order",
  getAllOders: "/api/autologin/getMyOrders",
  createBuyGame: "/api/server/order/buy-acc-game",

  //payment
  createPayment: "/api/server/purchase/create-vnpay-payment-order",
  getHistoryPayment: "/api/server/purchase/get-purchase-history",
  getVipRewardStatus: "/api/server/purchase/get-vip-reward-status",
  buyVip: "/api/server/purchase/buy-vip",
  updatePaymentOrderStatus: "/update-payment-order-status",
  getVipOrder: "/api/server/purchase/get-vip-order",
  updateVipOrder: "/api/server/purchase/update-vip",
};

export default APIs;
