import React from "react";
import { Image } from "antd";

const UserPanel = ({ activeFilter, setActiveFilter, filterIcons }) => {
    const filters = [
        "Game đã mua",
        "Danh sách yêu thích",
        "Đang thuê",
        "Tài khoản đã ghim",
    ];

    return (
        <div className="sm:pl-4 md:pl-6 pt-10 xs:w-[25%] sm:w-[25%] md:w-[23%]">
            <div className="rounded-2xl md:text-base bg-[#242333] border border-[#242333] p-2">
                {filters.map((filter, index, array) => (
                    <div
                        key={index}
                        className={`flex items-center justify-between mx-6 cursor-pointer ${activeFilter === filter ? "opacity-100" : "opacity-70"
                            } hover:opacity-100 transition-opacity duration-300 ${index < array.length - 1 ? "border-b border-gray-600" : ""
                            }`}
                        onClick={() => setActiveFilter(filter)}
                    >
                        <span className="text-white text-xs xs:text-xs sm:text-xs md:text-base font-normal py-3 pr-3 pl-0 flex items-center whitespace-nowrap">
                            <div className="sm:h-6 sm:w-6 md:h-8 md:w-8 lg:h-10 lg:w-10 mr-4 sm:mr-2 xs:h-4 xs:w-4 h-4 w-4">
                                <Image src={filterIcons[filter]} alt="" className="mr-2" />
                            </div>
                            {filter}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserPanel;
