import { useState, useEffect } from "react";

const useCooldown = (cooldownPeriod) => {
  const [isCooldown, setIsCooldown] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  const startCooldown = () => {
    if (!isCooldown) {
      setIsCooldown(true);
      setRemainingTime(cooldownPeriod);
    }
  };

  const resetCooldown = () => {
    setIsCooldown(true);
    setRemainingTime(cooldownPeriod);
  };

  useEffect(() => {
    let timer;
    if (isCooldown) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1000) {
            clearInterval(timer);
            setIsCooldown(false);
            return 0;
          }
          return prevTime - 1000;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isCooldown, cooldownPeriod]);

  return [isCooldown, startCooldown, remainingTime, resetCooldown];
};

export default useCooldown;
