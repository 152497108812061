import React, { useContext, useState } from "react";
import "tailwindcss/tailwind.css";
import { Menu } from "antd";
import infoIcon from "../../../assets/icons/header/identification 1.png";
import accountIcon from "../../../assets/icons/header/user.png";
import billIcon from "../../../assets/icons/header/bill 1.png";
import dollarBillIcon from "../../../assets/icons/header/dollar-bill 1.png";
import diamondIcon from "../../../assets/icons/header/diamond.png";
import logoutIcon from "../../../assets/icons/header/logout.png";
import { StateContext } from "../../../context/reducer";
import { useNavigate } from "react-router-dom";
import { getRentStatus } from "../../../context/actions";
import PopupError from "../../PopupConfirm/PopupError";
import { electronConstants } from "../../../constant";

const AccountDropdown = () => {
  const { state, dispatch } = useContext(StateContext);
  const { user } = state;
  const navigate = useNavigate();
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const isRentAccount = "Cảnh báo!";
  const isRentContent =
    "Bạn đang có tài khoản được thuê, nếu thoát trang này tài khoản của bạn sẽ đăng xuất";
  const [navigateLink, setNavigateLink] = useState("");

  const formatCurrency = (amount) => {
    return amount ? amount.toLocaleString() : "0";
  };

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("accessToken");
    if (window.electron.sendToMain) window.electron.sendToMain("logout-user");
  };

  const handleNavigate = (link) => {
    if (!user?.rentStatus) {
      navigate(link);
    } else {
      setIsErrorPopupVisible(true);
      setNavigateLink(link);
    }
  };

  const handleExitPage = () => {
    navigate(navigateLink);
    dispatch(getRentStatus(false));
    setIsErrorPopupVisible(false);

    if (window.electron.sendToMain) {
      window.electron.sendToMain(electronConstants.LOG_OUT_STEAM);
    }
  };

  return (
    <div className="bg-[#171721] rounded-lg opacity-95">
      <div className="flex justify-between p-2 min-w-[350px]">
        <div className="flex items-center">
          <img src={accountIcon} alt="" className="h-10 w-10 mx-2" />
          <div>
            <span className="text-white font-medium text-base block truncate max-w-[120px]">
              {user.account}
            </span>
            <p className="text-[#7ED0FF] font-medium">
              {formatCurrency(user.xu)} đ
            </p>
          </div>
        </div>
      </div>
      <hr className="border-t border-gray-50" />
      <Menu
        className="rounded-b-xl bg-[#171721] opacity-95"
        mode="vertical"
        style={{ borderColor: "transparent" }}
      >
        <Menu.Item
          key="1"
          style={{ color: "white", backgroundColor: "transparent" }}
          className="hover:bg-[#25252E]"
        >
          <div
            onClick={() => handleNavigate("/account/1")}
            className="flex font-medium items-center opacity-70 hover:opacity-100 text-base"
          >
            <img src={infoIcon} alt="" className="h-8 w-8 mr-2" />
            Thông tin tài khoản
          </div>
        </Menu.Item>

        <Menu.Item
          key="2"
          style={{ color: "white", backgroundColor: "transparent" }}
          className="hover:bg-[#25252E]"
        >
          <div
            onClick={() => handleNavigate("/payment")}
            className="flex font-medium items-center opacity-70 hover:opacity-100 text-base"
          >
            <img src={dollarBillIcon} alt="" className="h-8 w-8 mr-2" />
            Nạp tiền
          </div>
        </Menu.Item>
        <Menu.Item
          key="3"
          style={{ color: "white", backgroundColor: "transparent" }}
          className="hover:bg-[#25252E]"
        >
          <div
            onClick={() => handleNavigate("/account/2")}
            className="flex font-medium items-center opacity-70 hover:opacity-100 text-base"
          >
            <img src={diamondIcon} alt="" className="h-8 w-8 mr-2" /> Nâng Cấp
            VIP
          </div>
        </Menu.Item>
        <Menu.Item
          key="4"
          style={{ color: "white", backgroundColor: "transparent" }}
          className="hover:bg-[#25252E]"
        >
          <div
            onClick={() => handleNavigate("/account/3")}
            className="flex font-medium items-center opacity-70 hover:opacity-100 text-base"
          >
            <img src={billIcon} alt="" className="h-8 w-8 mr-2" /> Lịch sử giao
            dịch
          </div>
        </Menu.Item>
        <Menu.Item
          key="5"
          style={{ color: "white", backgroundColor: "transparent" }}
          className="hover:bg-[#25252E]"
        >
          {user ? (
            <div
              onClick={handleLogout}
              className="flex font-medium items-center opacity-70 hover:opacity-100 text-base"
            >
              <img src={logoutIcon} alt="" className="h-8 w-8 mr-2" /> Đăng xuất
            </div>
          ) : (
            <div
              onClick={() => handleNavigate("/")}
              className="flex font-medium items-center opacity-70 hover:opacity-100 text-base"
            >
              Đăng Nhập
            </div>
          )}
        </Menu.Item>
      </Menu>

      <PopupError
        visible={isErrorPopupVisible}
        onClose={() => setIsErrorPopupVisible(false)}
        title={isRentAccount}
        content={isRentContent}
        buttons={[
          {
            label: "Thoát",
            className:
              "px-4 py-2 text-sm font-medium text-white bg-[#FF6D6D] border border-gray-300 rounded-[30px] shadow-sm opacity-70 hover:opacity-100",
            onClick: () => handleExitPage(),
          },
          {
            label: "Đóng",
            className:
              "px-4 py-2 text-sm font-medium text-white bg-[#4BBEFF] border border-gray-300 rounded-[30px] shadow-sm opacity-70 hover:opacity-100",
            onClick: () => setIsErrorPopupVisible(false),
          },
        ]}
      />
    </div>
  );
};

export default AccountDropdown;
