import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryContants } from "../../../constant";
import {
  buyVip,
  createPayment,
  updatePaymentOrderStatus,
  updateVip,
} from "../../../apis/payment/payment";

export const useCreatePaymentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createPayment,
    onSuccess: () => {
      queryClient.invalidateQueries(queryContants.GET_ME);
    },
  });
};

export const useBuyVipMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: buyVip,
    onSuccess: () => {
      queryClient.invalidateQueries([
        queryContants.GET_ME,
        queryContants.GET_AMOUNT_HISTORY,
        queryContants.GET_PAYMENT_HISTORY,
      ]);
    },
  });
};
export const useUpdatePaymentOrderStatusMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updatePaymentOrderStatus,
    onSuccess: () => {
      queryClient.invalidateQueries([
        queryContants.GET_ME,
        queryContants.GET_AMOUNT_HISTORY,
        queryContants.GET_PAYMENT_HISTORY,
      ]);
    },
  });
};

export const useUpdateVipMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateVip,
    onSuccess: () => {
      queryClient.invalidateQueries([
        queryContants.GET_ME,
        queryContants.GET_AMOUNT_HISTORY,
        queryContants.GET_PAYMENT_HISTORY,
        queryContants.GET_VIP_ORDER,
      ]);
    },
  });
};