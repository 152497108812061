import React, { useContext, useRef, useState } from "react";
import { StateContext } from "../../../context/reducer";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

import { useChangePasswordMutation } from "../../../store/mutations/account/account.mutate";
import PopupConfirm from "../../PopupConfirm/PopupConfirm";
import PopupError from "../../PopupConfirm/PopupError";
import { useGetVipQuery } from "../../../store/queries/auth.query";

const AccountInfoUser = () => {
  const { state } = useContext(StateContext);
  const { user } = state;
  const oldPasswordRef = useRef();
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();

  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const { data: vipData } = useGetVipQuery();
  const [message, setMessage] = useState("");

  const { mutateAsync: changePassword, isLoading } =
    useChangePasswordMutation();

  const getVipRewardStatus = (isVip) => {
    const vip = vipData?.find((item) => item.id === JSON.parse(isVip));
    return (
      vip && (
        <div
          className={`flex items-center ${vip.color} bg-clip-text text-fill-transparent`}
        >
          <div> {vip.name}</div>
          <img src={vip.img} alt={vip.name} className="h-6 w-6 ml-2" />
        </div>
      )
    );
  };

  const handleChangePassword = async () => {
    const newPassword = newPasswordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;
    const oldPassword = oldPasswordRef.current.value;

    if (newPassword === "" || confirmPassword === "" || oldPassword === "") {
      setMessage("Vui lòng nhập đầy đủ thông tin");
      setIsErrorPopupVisible(true);
      return;
    }
    if (newPassword === oldPassword) {
      setMessage("Vui lòng nhập mật khẩu mới khác mật khẩu cũ");
      setIsErrorPopupVisible(true);
      return;
    }

    if (newPassword.length < 4) {
      setMessage("Mật khẩu mới phải có đủ 4 ký tự!");
      setIsErrorPopupVisible(true);
      return;
    }

    if (newPassword !== confirmPassword) {
      setMessage("Mật khẩu mới không khớp!");
      setIsErrorPopupVisible(true);
      return;
    }
    try {
      const changePasswordData = {
        oldPassword,
        newPassword,
      };
      const res = await changePassword(changePasswordData);

      if (res) {
        setMessage("Đổi mật khẩu thành công");
        setIsPopupVisible(true);
      }
    } catch (error) {
      setMessage(error?.response?.data ?? "Đổi mật khẩu thất bại");
      setIsErrorPopupVisible(true);
    }
  };

  return (
    <div className="relative">
      <div className="flex justify-center items-center mb-8 relative">
        <h2 className="lg:text-2xl font-semibold sm:text-xl ">
          Thông tin tài khoản
        </h2>
        {user?.is_vip !== "0" && (
          <div className="absolute right-0 flex items-center">
            <p className="flex items-center text-lg px-1 font-semibold">
              {getVipRewardStatus(user?.is_vip)}
            </p>
          </div>
        )}
      </div>

      <div className="mb-6 sm:mb-4 flex justify-evenly">
        <div className="flex-col sm:min-w-[40%] xs:min-w-[45%] lg:min-w-96 p-2 rounded-lg font-semibold bg-[#171721] mb-4 pb-2 sm:text-base md:text-lg lg:text-xl text-base ">
          <label className="text-white py-2 mx-2">Tên tài khoản</label>
          <p className="mx-2 mt-1">{user?.account}</p>
        </div>
        <div className="flex-col sm:min-w-[40%] md:min-w-[40%] xs:min-w-[45%] lg:min-w-96 p-2 rounded-lg font-semibold bg-[#171721] mb-4 pb-2 sm:text-base md:text-lg lg:text-xl text-base">
          <label className="text-white py-2 mx-2">Địa chỉ email</label>
          <p className="mx-2 mt-1">{user?.email}</p>
        </div>
      </div>

      {/* <hr className="my-4 border-t-2 border-gray-400" /> */}

      <h3 className="lg:text-2xl sm:text-xl font-semibold mb-8 sm:mb-6 flex justify-center">
        Đổi mật khẩu
      </h3>

      <div className="flex justify-evenly">
        <div className="sm:min-w-[40%] xs:min-w-[45%] lg:min-w-96">
          <div className="bg-[#171721] rounded-lg p-3 relative">
            <p className="text-white mb-2 font-semibold sm:text-base md:text-lg lg:text-xl text-base">
              Mật khẩu cũ
            </p>
            <input
              type={oldPasswordVisible ? "text" : "password"}
              placeholder="Nhập mật khẩu cũ."
              ref={oldPasswordRef}
              className="w-full bg-transparent border-b border-gray-500 focus:outline-none focus:border-white sm:text-[0.875rem] md:text-lg lg:text-xl xs:text-[0.75rem]"
            />
            <span
              onClick={() => setOldPasswordVisible(!oldPasswordVisible)}
              className="absolute right-3 top-[45px] cursor-pointer"
            >
              {oldPasswordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            </span>
          </div>
        </div>
        <div className="sm:min-w-[40%] xs:min-w-[45%] lg:min-w-96">
          <div className="bg-[#171721] rounded-lg p-3 mb-4 relative">
            <p className="text-white mb-2 font-semibold sm:text-base md:text-lg lg:text-xl text-base">
              Mật khẩu mới
            </p>
            <input
              type={newPasswordVisible ? "text" : "password"}
              ref={newPasswordRef}
              className="w-full bg-transparent border-b border-gray-500 focus:outline-none focus:border-white"
              placeholder="Mật khẩu ít nhất phải 4 ký tự"
            />
            <span
              onClick={() => setNewPasswordVisible(!newPasswordVisible)}
              className="absolute right-3 top-[45px] cursor-pointer"
            >
              {newPasswordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            </span>
          </div>
          <div className="bg-[#171721] rounded-lg p-3 relative">
            <p className="text-white mb-1 font-semibold sm:text-base md:text-lg lg:text-xl text-base">
              Nhập lại mật khẩu mới
            </p>
            <input
              type={confirmPasswordVisible ? "text" : "password"}
              ref={confirmPasswordRef}
              className="w-full bg-transparent border-b border-gray-500 focus:outline-none focus:border-white"
              placeholder="Nhập lại mật khẩu mới."
            />
            <span
              onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
              className="absolute right-3 top-[42px] cursor-pointer"
            >
              {confirmPasswordVisible ? (
                <EyeOutlined />
              ) : (
                <EyeInvisibleOutlined />
              )}
            </span>
          </div>
          <div className="flex justify-end mt-4">
            <button
              onClick={handleChangePassword}
              disabled={isLoading}
              className="bg-[#00A1FF] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-3xl focus:outline-none focus:shadow-outline "
            >
              {isLoading ? "Đang xử lý..." : "Xác nhận"}
            </button>
          </div>
        </div>
      </div>

      <PopupConfirm />
      <PopupError
        visible={isErrorPopupVisible}
        onClose={() => setIsErrorPopupVisible(false)}
        title={"Cảnh báo!"}
        content={message}
        buttons={[
          {
            label: "Đóng",
            className:
              "px-4 py-2 text-sm font-medium text-white bg-[#4BBEFF] border border-gray-300 rounded-[30px] shadow-sm opacity-70 hover:opacity-100",
            onClick: () => setIsErrorPopupVisible(false),
          },
        ]}
      />
      <PopupConfirm
        visible={isPopupVisible}
        onClose={() => setIsPopupVisible(false)}
        title={"Hoàn tất!"}
        content={message}
        buttons={[
          {
            label: "Đóng",
            className:
              "px-4 py-2 text-sm font-medium text-white bg-[#4BBEFF] border border-gray-300 rounded-[30px] shadow-sm opacity-70 hover:opacity-100",
            onClick: () => setIsPopupVisible(false),
          },
        ]}
      />
    </div>
  );
};

const AccountInfo = () => {
  return <AccountInfoUser />;
};

export default AccountInfo;
