const Hide = ({ className }) => {
  return (
    <svg
      className={`w-[10px] h-[1px] fill-[#D9D9D9]  ${className}`}
      viewBox="0 0 10 1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H10V1H0V0Z" />
    </svg>
  );
};

export default Hide;
