import axiosServer from "../../axios/axiosServer";
import APIs from "../../constant/endpoint";

export const createPayment = async (data) => {
  const res = await axiosServer.post(APIs.createPayment, data);
  return res.data;
};

export const getHistoryPayment = async (props, pageSize, userId) => {
  const res = await axiosServer.get(APIs.getHistoryPayment, {
    params: { page: props.pageParam, pageSize, userId },
  });
  return res.data;
};

export const getVipRewardStatus = async (data) => {
  const res = await axiosServer.post(APIs.getVipRewardStatus, data);
  return res.data;
};

export const buyVip = async (data) => {
  const res = await axiosServer.post(APIs.buyVip, data);
  return res.data;
};

export const updatePaymentOrderStatus = async (data) => {
  const res = await axiosServer.post(APIs.updatePaymentOrderStatus, data);
  return res.data;
};

export const getVipOrder = async (userId) => {
  const res = await axiosServer.get(APIs.getVipOrder, {
    params: userId,
  });
  return res.data;
};

export const updateVip = async (data) => {
  const res = await axiosServer.post(APIs.updateVipOrder, data);
  return res.data;
};